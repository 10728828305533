<script setup lang="ts">
defineProps({
    colors: {
        type: Array as PropType<string[]>,
        default: () => ["#93A4BC", "#5A6273"]
    }
});

const randomId = computed(() => Math.random().toString(36).substr(2, 9));
</script>

<template>
    <svg
        width="32"
        height="30"
        viewBox="0 0 32 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14 23C14 26.866 10.866 30 7 30C3.13401 30 0 26.866 0 23C0 19.134 3.13401 16 7 16C10.866 16 14 19.134 14 23Z"
            :fill="'url(#paint0_linear_308_516' + randomId + ')'"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16 14C19.866 14 23 10.866 23 7C23 3.13403 19.866 0 16 0C12.134 0 9 3.13403 9 7C9 10.866 12.134 14 16 14ZM16 10C17.6569 10 19 8.65686 19 7C19 5.34314 17.6569 4 16 4C14.3431 4 13 5.34314 13 7C13 8.65686 14.3431 10 16 10Z"
            :fill="'url(#paint1_linear_308_516' + randomId + ')'"
        />
        <path
            d="M32 23C32 26.866 28.866 30 25 30C21.134 30 18 26.866 18 23C18 19.134 21.134 16 25 16C28.866 16 32 19.134 32 23Z"
            :fill="'url(#paint2_linear_308_516' + randomId + ')'"
        />
        <defs>
            <linearGradient
                :id="'paint0_linear_308_516' + randomId"
                x1="16"
                y1="0"
                x2="16"
                y2="30"
                gradientUnits="userSpaceOnUse"
            >
                <stop :stop-color="colors[0]" />
                <stop offset="1" :stop-color="colors[1]" />
            </linearGradient>
            <linearGradient
                :id="'paint1_linear_308_516' + randomId"
                x1="16"
                y1="0"
                x2="16"
                y2="30"
                gradientUnits="userSpaceOnUse"
            >
                <stop :stop-color="colors[0]" />
                <stop offset="1" :stop-color="colors[1]" />
            </linearGradient>
            <linearGradient
                :id="'paint2_linear_308_516' + randomId"
                x1="16"
                y1="0"
                x2="16"
                y2="30"
                gradientUnits="userSpaceOnUse"
            >
                <stop :stop-color="colors[0]" />
                <stop offset="1" :stop-color="colors[1]" />
            </linearGradient>
        </defs>
    </svg>
</template>
